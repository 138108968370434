<template>
  <div class="rounded-md bg-white p-4">
    <div class="text-subheader">Sales Trend</div>
    <div id="chart">
      <apexchart
        type="bar"
        height="250"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    series: [
      {
        data: [21, 22, 10, 28],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e);
          },
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#007AA9"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Jan - March", "April - June", "July - Sep", "Oct - Dec"],
        labels: {
          style: {
            // colors: colors,
            fontSize: "12px",
          },
        },
      },
    },
  }),
};
</script>
