<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="2">
        <rm-drop-down outlined :options="metricOptions" />
      </b-col>
      <b-col cols="10" />
      <b-col cols="3" v-for="(metric, ix) in dashboardMetrics" :key="ix">
        <rm-metric-card :metric="metric" />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="7">
        <div class="">
          <rm-insights />
        </div>
      </b-col>
      <b-col cols="5">
        <div>
          <rm-plot-sales />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <div class="d-flex justify-content-between">
          <rm-rating-card
            title="Best Selling"
            color="success"
            action="action"
          />
          <rm-rating-card
            title="Least Selling"
            color="danger"
            action="action"
          />
          <rm-rating-card title="Most Plots Sold" color="success" />
          <rm-rating-card title="Least Plots Sold" color="danger" />
          <rm-rating-card title="Add" color="transparent" isAdd />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RmMetricCard from "./components/RmMetricCard.vue";
import RmInsights from "./components/RmInsights.vue";
import RmPlotSales from "./components/RmPlotSales.vue";
import RmRatingCard from "./components/RmRatingCard.vue";

export default {
  components: {
    RmMetricCard,
    RmInsights,
    RmPlotSales,
    RmRatingCard,
  },
  data: () => ({
    metricOptions: ["All Time"],

    dashboardMetrics: [
      {
        name: "Total Clients",
        amount: "25K",
        growth: +100,
        icon: "total-clients",
      },
      {
        name: "Total Realtors",
        amount: "250",
        growth: +15,
        icon: "total-realtors",
      },
      {
        name: "Total Sales",
        amount: "N256M",
        growth: +100,
        icon: "total-sales",
      },
      {
        name: "Commissions Paid",
        amount: "N5.2M",
        growth: -22.5,
        icon: "commissions-paid",
      },
    ],
  }),
};
</script>
