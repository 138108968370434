<template>
  <div
    :class="`rating-card bg-${color} rounded-md d-flex flex-column p-4 ${
      isAdd ? 'dashed justify-content-center align-items-center' : ''
    }`"
  >
    <div class="text-black" v-if="!isAdd">{{ title }}</div>
    <div
      v-if="isAdd"
      class="add-icon bg-white rounded-circle d-flex justify-content-center align-items-center"
    >
      <b-icon icon="plus" scale="3" />
    </div>
    <div>
      <slot name="body" />
    </div>
    <div class="mt-auto d-flex w-100 align-items-center" v-if="action">
      <div class="text-sm-2 mr-5 text-primary--gradient">View More</div>
      <b-icon icon="arrow-right" class="text-primary" scale="0.8"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    action: {
      type: String,
    },
    color: {
      type: String,
      default: "primary",
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-card {
  height: 15rem;
  width: 12rem;

  &.dashed {
    border: 1px dashed rgba(black, 0.1);
  }

  .add-icon {
    height: 3rem;
    width: 3rem;
  }
}
</style>
