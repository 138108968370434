<template>
  <div class="rounded-md bg-white p-4">
    <div class="text-subheader">Insights</div>
    <b-row class="mb-n5">
      <b-col cols="3">
        <rm-drop-down
          outlined
          placeholder="Sales Trend"
          :options="trendOptions"
        /> </b-col
    ></b-row>
    <div id="chart">
      <apexchart
        type="line"
        height="220"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // apexchart: VueApexCharts,
  },
  data: () => ({
    trendOptions: ["All Time"],
    series: [
      {
        name: "Current Year",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 50, 34, 90],
      },
      {
        name: "Previous Year",
        data: [49, 62, 69, 91, 148, 10, 41, 35, 51, 128, 100, 120],
      },
    ],
    chartOptions: {
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        markers: {
          width: 15,
          height: 5,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#007AA9", "#F80D0D"],
      stroke: {
        curve: "straight",
        width: 2,
      },
      title: {
        text: "",
        align: "left",
        style: {
          opacity: 0,
        },
      },
      grid: {
        show: true,
        borderColor: "#E0E0E0",
        strokeDashArray: 5,
        row: {
          colors: ["transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
  }),
};
</script>
